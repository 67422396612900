/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import { isNil, uniqueId } from 'lodash';
import { Link } from 'react-router-dom-v5-compat';
import {
    Icons,
    Inline,
    VerticalDivider,
    Heading,
    StarRating,
    MobileCoverageRating,
    FavoriteHeart,
    Button,
} from 'sarsaparilla';

import { FacilityPageHeaderPlaceholder } from './FacilityPageHeaderPlaceholder';

/**
 * heading: top level heading of the page
 * nearText: appends "Near" to text -- can be on same line as parentLinkText
 * parentLinkText: anchor tag text (used in combo with parentLinkUrl)
 * parentLinkUrl: provides url to parentLinkText
 * isParentLinkSameService: "true" removes external link icon
 * rating: star icons
 * ratingCount: number next to star icons
 */

export default function FacilityPageHeader({
    isLoading = false,
    heading,
    isPartOfParent = false,
    parentLinkText,
    parentLinkUrl,
    isParentLinkSameService = false,
    nearText,
    rating,
    reviewCount,
    mobileCoverageRating,
    favoriteFacilityId,
    favoriteEntityId,
    favoriteEntityType,
    favoriteParentId,
    favoriteParentType,
    className,
    onClickRules,
    onClickRatings,
    showRulesLink,
}) {
    if (isLoading) {
        return <FacilityPageHeaderPlaceholder />;
    }
    const locationChain = [];
    const reviewChain = [];
    const hasParentLink = parentLinkText && parentLinkUrl;
    const hasLocationChain = hasParentLink || nearText;
    const hasRating = !isNil(rating);
    const hasMobileCoverageRating = !isNil(mobileCoverageRating);
    const hasFavoriteButton = favoriteFacilityId || favoriteEntityId;

    if (hasParentLink && !isPartOfParent) {
        if (isParentLinkSameService) {
            locationChain.push(
                <Link to={parentLinkUrl} key="link" translate="no">
                    {parentLinkText}
                </Link>
            );
        } else {
            locationChain.push(
                <a href={parentLinkUrl} key="link" translate="no">
                    {parentLinkText}
                </a>
            );
        }
    }
    if (hasParentLink && isPartOfParent) {
        if (isParentLinkSameService) {
            locationChain.push(
                <span key="partOf">
                    Part of &nbsp;
                    <Link to={parentLinkUrl} key="link" translate="no">
                        {parentLinkText}
                    </Link>
                </span>
            );
        } else {
            locationChain.push(
                <span key="partOf">
                    Part of &nbsp;
                    <a href={parentLinkUrl} key="link" translate="no">
                        {parentLinkText}
                    </a>
                </span>
            );
        }
    }
    if (hasParentLink && nearText) {
        locationChain.push(<VerticalDivider key="divider" />);
    }
    if (nearText) {
        locationChain.push(<span key="near">{nearText}</span>);
    }
    if (hasRating) {
        reviewChain.push(
            <Button
                key={uniqueId()}
                appearance="link"
                onClick={onClickRatings}
                isDisabled={reviewCount === 0}
            >
                <StarRating rating={rating} reviewCount={reviewCount} key="stars" />
            </Button>
        );
    }
    if (hasRating && hasMobileCoverageRating) {
        reviewChain.push(<VerticalDivider key="rating-mobile-divider" />);
    }
    if (hasMobileCoverageRating) {
        reviewChain.push(
            <Button
                key={uniqueId()}
                appearance="link"
                onClick={onClickRatings}
                isDisabled={reviewCount === 0}
            >
                <MobileCoverageRating rating={mobileCoverageRating} key="mobile" />
            </Button>
        );
    }
    if (hasMobileCoverageRating && hasFavoriteButton) {
        reviewChain.push(<VerticalDivider key="mobile-favorite-divider" />);
    }

    if (hasFavoriteButton) {
        reviewChain.push(
            <FavoriteHeart
                id={favoriteFacilityId}
                entityId={favoriteEntityId}
                entityType={favoriteEntityType}
                parentId={favoriteParentId}
                parentType={favoriteParentType}
                showFavoriteText
                key="favorite-button"
            />
        );
    }

    if (showRulesLink && onClickRules) {
        reviewChain.push(<VerticalDivider key="facility-rules-divider" />);
        reviewChain.push(
            <Button
                key={uniqueId()}
                appearance="link"
                iconBeforeElement={<Icons.IconAnnouncement />}
                onClick={onClickRules}
            >
                Reservation Rules
            </Button>
        );
    }

    return (
        <div data-component="FacilityPageHeader" className={className}>
            <div>
                <Heading headingLevel={1} appearance="title" translate="no">
                    {heading}
                </Heading>
                {hasLocationChain && (
                    <div className="location-chain mt-1 mb-1">
                        <Inline space={['none', 'sm', 'md']}>{locationChain}</Inline>
                    </div>
                )}
                {reviewChain.length > 0 && (
                    <div className="rating-chain pt-1">
                        <Inline space={['sm', 'sm', 'md']}>{reviewChain}</Inline>
                    </div>
                )}
            </div>
        </div>
    );
}

FacilityPageHeader.propTypes = {
    heading: PropTypes.string,
    parentLinkText: PropTypes.string,
    parentLinkUrl: PropTypes.string,
    isPartOfParent: PropTypes.bool,
    nearText: PropTypes.string,
    rating: PropTypes.number,
    reviewCount: PropTypes.number,
    mobileCoverageRating: PropTypes.number,
    favoriteFacilityId: PropTypes.string,
    favoriteEntityId: PropTypes.string,
    favoriteEntityType: PropTypes.string,
    favoriteParentId: PropTypes.string,
    favoriteParentType: PropTypes.string,
    className: PropTypes.string,
    isLoading: PropTypes.bool,
    isParentLinkSameService: PropTypes.bool,
    showRulesLink: PropTypes.bool,
    onClickRules: PropTypes.func,
    onClickRatings: PropTypes.func,
};
