/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import PropTypes from 'prop-types';
import cx from 'classnames';

import { Heading, Icons, Box, DisplayHtmlContent } from 'sarsaparilla';

import type { NeedToKnowListItemType } from './NeedToKnowList';

export default function NeedToKnowListItem({
    title,
    priority = 'low',
    description,
    headingLevel = 3,
}: NeedToKnowListItemType) {
    const isHighPriority = priority === 'high';

    return (
        <li data-shared-ntk-list-item>
            <Box
                alignX="center"
                alignY="center"
                className={cx('shared-ntk-list-item-bullet', {
                    'high-priority': isHighPriority,
                    'low-priority': !isHighPriority,
                })}
            >
                {isHighPriority ? <Icons.IconWarning /> : <Icons.IconInfo />}
            </Box>
            {!!title && (
                <Heading
                    data-shared-ntk-list-item-heading
                    headingLevel={headingLevel}
                    appearance="small"
                >
                    {title}
                </Heading>
            )}
            <DisplayHtmlContent html={description} />
        </li>
    );
}

NeedToKnowListItem.propTypes = {
    title: PropTypes.string,
    priority: PropTypes.string,
    description: PropTypes.node,
    headingLevel: PropTypes.number,
};
