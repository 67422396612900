/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

// ****************************************************************
// Only export image paths that are directly used in service apps!
// ****************************************************************

import logoPng from './logos/logo.png';
import qrLogoPng from './logos/qr-logo.png';

export const logoPngSrc: string = logoPng;
export const qrLogoPngSrc: string = qrLogoPng;
