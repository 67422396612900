/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/* eslint-disable no-restricted-syntax */

import * as React from 'react';
import axios from 'axios';
import {
    ContentContainer,
    Heading,
    FlexRow,
    FlexCol,
    TextField,
    TextArea,
    Button,
    RecaptchaCheckbox,
    Alert,
    PhoneNumberField,
    EmailField,
    FormValidationAlert,
    type FormValidationAlertErrors,
} from 'sarsaparilla';

import imageLgAvif from './images/contact-section/contact-info-bg-lg.avif';
import imageLgWebp from './images/contact-section/contact-info-bg-lg.webp';
import imageSmAvif from './images/contact-section/contact-info-bg-sm.avif';
import imageSmWebp from './images/contact-section/contact-info-bg-sm.webp';

export function ContactUsSection() {
    const formRefs = React.useRef<any[]>([]);
    const [token, setToken] = React.useState('');
    const [formErrors, setFormErrors] = React.useState<FormValidationAlertErrors | null>(
        null
    );
    const [requestStatus, setRequestStatus] = React.useState<
        'IDLE' | 'SUBMITTING' | 'FAIL' | 'SUCCESS'
    >('IDLE');

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setFormErrors(null);
        setRequestStatus('SUBMITTING');

        const errors: FormValidationAlertErrors = [];
        formRefs.current.forEach((ref) => {
            const validation = ref.validate();
            if (validation.isInvalid) {
                errors.push({ ...validation, inputId: validation.id });
            }
        });

        if (errors.length > 0) {
            setFormErrors(errors);
            return;
        }

        const formData = new FormData(event.currentTarget);
        const data = Object.fromEntries(formData.entries());

        try {
            const body = {
                new_inv: {
                    name: data.name,
                    title: data.title,
                    phone: data.phone,
                    email: data.email,
                    message: data.message,
                    facility_name: data.areaOrFacility,
                    agency: data.organization,
                    token,
                },
            };
            const resp = await axios.post(`${process.env.API}/ugc/newinv/save`, body);

            if (resp.status === 200) {
                setRequestStatus('SUCCESS');
                return;
            }

            setRequestStatus('FAIL');
        } catch (error) {
            setRequestStatus('FAIL');
        }
    };

    const addFormRef = (index: number) => (ref: any) => {
        formRefs.current[index] = ref;
    };

    return (
        <section id="contact-us" className="nav-ni-contact-us-wrap">
            <div className="nav-ni-contact-us-background-image">
                <picture>
                    <source
                        type="image/avif"
                        sizes="100vw"
                        srcSet={`${imageLgAvif} 1440w,${imageSmAvif} 992w`}
                    />

                    <img
                        alt="Snowy mountain range next to a body of water at Inyo National Forest"
                        src={imageSmWebp}
                        srcSet={`${imageLgWebp} 1440w,${imageSmWebp} 992w`}
                    />
                </picture>
            </div>

            <ContentContainer className="my-0">
                <FlexRow>
                    <FlexCol
                        mdOffset={1}
                        md={10}
                        lgOffset={2}
                        lg={8}
                        xxlOffset={3}
                        xxl={6}
                        className="nav-ni-contact-us-body"
                    >
                        <Heading
                            headingLevel={3}
                            appearance="h4"
                            className="nav-ni-contact-us-header mb-3"
                        >
                            Tell us about your federal facility and how we can contact you
                            about adding your location to Recreation.gov.
                        </Heading>

                        <p className="nav-ni-contact-us-sub-text-note">
                            Please note that information submitted through this form is
                            directed to our New Inventory Team for new location
                            onboarding. For Recreation.gov users looking for information
                            about accounts, reservations, or refunds, please check our{' '}
                            <a
                                href="https://recreationonestopprod.servicenowservices.com/external"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Help Center
                            </a>{' '}
                            or <a href="/contact-us">Contact Us</a> page for support with
                            your request.
                        </p>

                        <p className="nav-ni-contact-us-sub-text-required">
                            Any fields marked with a red asterisk (
                            <abbr className="rec-label-required-indicator">*</abbr>) are
                            required to submit your feedback. Please make sure you
                            complete these required fields.
                        </p>

                        <FlexRow>
                            <FlexCol smOffset={1} sm={10} lgOffset={2} lg={8}>
                                {requestStatus === 'FAIL' ? (
                                    <Alert type="error" shouldFocusOnMount>
                                        Something went wrong. Please try submitting your
                                        information again.
                                    </Alert>
                                ) : requestStatus === 'SUCCESS' ? (
                                    <Alert
                                        type="success"
                                        className="mb-4"
                                        shouldFocusOnMount
                                    >
                                        Thank you for submitting your information. Someone
                                        from our team will contact you shortly.
                                    </Alert>
                                ) : null}
                            </FlexCol>
                        </FlexRow>

                        <FormValidationAlert errors={formErrors} className="mb-4" />

                        {requestStatus !== 'SUCCESS' && (
                            <form onSubmit={onSubmit} noValidate>
                                <FlexRow>
                                    <FlexCol>
                                        <FlexRow>
                                            <FlexCol lg={6}>
                                                {/* Name Field */}
                                                <TextField
                                                    className="rec-form-item-wrap"
                                                    id="name"
                                                    name="name"
                                                    label="Name"
                                                    isRequired
                                                    ref={addFormRef(0)}
                                                />
                                            </FlexCol>
                                            <FlexCol lg={6}>
                                                <TextField
                                                    className="rec-form-item-wrap"
                                                    id="title"
                                                    name="title"
                                                    label="Title"
                                                    isRequired
                                                    ref={addFormRef(1)}
                                                />
                                            </FlexCol>
                                        </FlexRow>

                                        <FlexRow>
                                            <FlexCol lg={6}>
                                                <PhoneNumberField
                                                    className="rec-form-item-wrap"
                                                    id="phone-number"
                                                    name="phone"
                                                    label="Phone Number"
                                                    isRequired
                                                    ref={addFormRef(2)}
                                                />
                                            </FlexCol>

                                            <FlexCol lg={6}>
                                                <EmailField
                                                    className="rec-form-item-wrap"
                                                    id="email"
                                                    name="email"
                                                    label="Email Address"
                                                    isRequired
                                                    ref={addFormRef(3)}
                                                />
                                            </FlexCol>
                                        </FlexRow>

                                        <FlexRow>
                                            <FlexCol lg={6}>
                                                {/* Area or Facility Field */}
                                                <TextField
                                                    className="rec-form-item-wrap"
                                                    id="areaOrFacility"
                                                    name="areaOrFacility"
                                                    label="Area or Facility"
                                                    isRequired
                                                    ref={addFormRef(4)}
                                                />
                                            </FlexCol>

                                            <FlexCol lg={6}>
                                                {/* Area or Organization */}
                                                <TextField
                                                    className="rec-form-item-wrap"
                                                    id="organization"
                                                    name="organization"
                                                    label="Federal Agency/Concessionaire"
                                                    isRequired
                                                    ref={addFormRef(5)}
                                                />
                                            </FlexCol>
                                        </FlexRow>

                                        {/* Message Field */}
                                        <div className="rec-form-item-wrap">
                                            <TextArea
                                                id="message"
                                                label="Message"
                                                name="message"
                                                type="textarea"
                                                rows="8"
                                            />
                                        </div>

                                        <FlexRow
                                            justifyContent="space-between"
                                            alignItems="center"
                                        >
                                            <FlexCol className="rec-recaptcha-wrap">
                                                <RecaptchaCheckbox
                                                    action="new_imp_2022"
                                                    size="normal"
                                                    onChange={setToken}
                                                    sitekey={
                                                        process.env
                                                            .RECAPTCHA_ENTERPRISE_KEY
                                                    }
                                                />
                                            </FlexCol>
                                            <FlexCol className="nav-ni-contact-us-submit-btn-wrap">
                                                <Button
                                                    type="submit"
                                                    isLoading={
                                                        requestStatus === 'SUBMITTING'
                                                    }
                                                >
                                                    Submit
                                                </Button>
                                            </FlexCol>
                                        </FlexRow>
                                    </FlexCol>
                                </FlexRow>
                            </form>
                        )}
                    </FlexCol>
                </FlexRow>
            </ContentContainer>
        </section>
    );
}
