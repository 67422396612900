/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { Icons, StringHelper, MapHelper, Button } from 'sarsaparilla';
import { createComponent, handleClosePopUp } from './common';

export default function searchMapPopup(feature) {
    let location;
    if (!feature) return location;
    try {
        location = typeof feature === 'object' ? feature : JSON.parse(feature);
    } catch (e) {
        return location;
    }

    // Rating segment
    const hasRating = location?.number_of_ratings > 0;
    const ratingValue = location?.average_rating;
    const decVal = ratingValue % 1;
    const isHalfStar = decVal >= 0.25 && decVal < 0.75;
    const wholeVal = isHalfStar ? Math.trunc(ratingValue) : Math.round(ratingValue);
    const halfString = isHalfStar ? '_5' : '';

    const ratings = (
        <div className="rating-info">
            <div className="ratview-star-display-wrapper">
                <div className={`sprite star-rating rating_${wholeVal}${halfString}`} />
            </div>
            <div className="ratview-star-text-wrapper">
                ({location.number_of_ratings})
            </div>
        </div>
    );

    const imageCard = {
        title: location.preview_image_url
            ? 'Search result preview image'
            : 'Photo Unavailable',
        url: location.preview_image_url
            ? location.preview_image_url
            : `${process.env.CDN}/img/global/unavailable/image-unavailable.jpg`,
        location:
            location.city && location.state_code
                ? `Near ${location.city}, ${location.state_code}`
                : null,
        description: location.description ? `${location.description}...` : null,
        targetUri:
            location.entity_id && location.name ? MapHelper.getTargetUri(location) : null,
    };

    let availability;

    if (location.type === 'CAMPING_LOTTERY') {
        availability = <div className="map-popup-lottery-info">Lottery</div>;
    } else if (location.availability && location.entity_type === 'campground') {
        if (location.availability === 'partially available') {
            availability = (
                <div className="map-popup-availability-info">Partially Available</div>
            );
        } else if (location.availability === 'available') {
            availability = <div className="map-popup-availability-info">Available</div>;
        } else if (location.availability === 'not reservable') {
            availability = (
                <div className="map-popup-firstcome-info">First-come, First-served</div>
            );
        } else {
            availability = (
                <div className="map-popup-unavailability-info">
                    {StringHelper.toTitleCase(location.availability)}
                </div>
            );
        }
    }

    // Make "tour" plural if more than one tour exists
    const tourLabel = location.tours_count > 1 ? 'tours' : 'tour';

    // Make "site" plural if more than one site exists
    const sitesLabel = location.campsites_count > 1 ? 'sites' : 'site';

    const isAccessible = location?.accessible_campsites_count;

    const popup = (
        <div className="map-popup">
            <Button
                className="close-pop-up-btn"
                appearance="subtle"
                size="sm"
                shouldFitContainer={false}
                onClick={handleClosePopUp}
                iconBeforeElement={<Icons.IconClose />}
                screenReaderTextBefore="Close the modal"
                gaTrackingId="681762123001"
            />

            {imageCard.url && imageCard.title && (
                <div className="map-popup-image">
                    <img src={imageCard.url} alt={imageCard.title} />
                    {!!availability && availability}
                </div>
            )}
            <div className="map-popup-message">
                <span className="h4 map-popup-title">{location.name}</span>
                <ul className="map-popup-location-count">
                    {!!imageCard.location && <li>{imageCard.location}</li>}
                    {!!(location.reservable && location.campsites_count) && (
                        <li>
                            {location.campsites_count} {sitesLabel}
                        </li>
                    )}
                    {!!location.tours_count && (
                        <li>
                            {location.tours_count} {tourLabel}
                        </li>
                    )}
                </ul>

                {isAccessible && (
                    <div className="map-popup-accessibility">
                        <Icons.IconAccessible className="accessible-icon" size="sm" />{' '}
                        Accessible
                    </div>
                )}

                {hasRating && ratings}

                {imageCard.targetUri && (
                    <div className="map-popup-button">
                        <Button
                            appearance="link"
                            href={imageCard.targetUri}
                            target="_blank"
                            rel="noopener noreferrer"
                            size="md"
                            title="View Details"
                            aria-label="View Details"
                            className="rec-button-link"
                            gaTrackingId="317756999568"
                            gaTrackingValue={location.name}
                        >
                            View Details
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );

    return createComponent(popup);
}
