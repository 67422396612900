/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/* eslint-disable no-restricted-syntax */

import { Heading, TextBlock } from 'sarsaparilla';
import LateCancellationTable from '../cancellationTable/LateCancellationTable';
import ModifyReservationTable from '../cancellationTable/ModifyReservationTable';

interface PoliciesSectionProps {
    policiesAnchor: string;
    campingAnchor: string;
    cancelAnchor: string;
    noShowAnchor: string;
    modifyAnchor: string;
    departuresAnchor: string;
    dueNowAnchor: string;
    downPaymentAnchor: string;
}

export default function PoliciesSection({
    policiesAnchor = '',
    campingAnchor = '',
    cancelAnchor = '',
    noShowAnchor = '',
    modifyAnchor = '',
    departuresAnchor = '',
    dueNowAnchor = '',
    downPaymentAnchor = '',
}: PoliciesSectionProps) {
    return (
        <section className="mb-8" id={policiesAnchor}>
            <Heading
                headingLevel={2}
                appearance="h1"
                className="rules-reservation-policies-section-heading mb-4"
            >
                Policies and Rules for Different Types of Activities
            </Heading>

            <Heading headingLevel={2} appearance="h2" className="mb-3" id={campingAnchor}>
                Camping, Day-Use, Scan & Pay and Cabin Reservations
            </Heading>
            <Heading headingLevel={2} appearance="h3" className="mb-3">
                Cancellations
            </Heading>

            <Heading headingLevel={2} appearance="h4" id={cancelAnchor}>
                Cancelling a Reservation
            </Heading>
            <TextBlock width="xl">
                <p>
                    Customers may cancel a reservation prior to the day of the reservation
                    check-in date, both online, through the app, or through the contact
                    center. A $10 cancellation fee may apply. For most facilities,
                    customers may not cancel a reservation after 12:00am local time on the
                    reservation check-in date. In these cases, the contact center will not
                    be able to assist either, and only an on-site staff member will be
                    able to assist. There are some exceptions to this rule where a
                    facility will allow a same-day self-cancellation (also known as a
                    “mid-stay change”). Check the facility&apos;s page for additional
                    information on their specific business rules.
                </p>
            </TextBlock>
            <Heading headingLevel={2} appearance="h4">
                Late Cancellations
            </Heading>
            <TextBlock width="xl">
                <p className="mb-2">
                    Depending on when a reservation is cancelled in relation to the
                    check-in date, it may be considered a late cancellation and an
                    additional amount may be withheld.
                </p>
            </TextBlock>
            <LateCancellationTable />
            <Heading headingLevel={2} appearance="h4">
                Individual Campsites
            </Heading>
            <TextBlock width="xl">
                <p>
                    Camping reservations cancelled on the day before arrival (&quot;late
                    cancellation&quot;) will incur a $10.00 cancellation fee AND forfeit
                    the first night&apos;s fees (not to exceed the total paid for the
                    original reservation).
                </p>
                <p className="ml-4 mr-4">
                    <i>
                        For example, if your check-in date is August 4th at 11:00am
                        Pacific Standard Time (facility&apos;s local time), a late
                        cancellation would be any time after 12:00am local time on August
                        3rd.
                    </i>
                </p>
                <p>
                    Late cancellations of a one-night campsite reservation will forfeit
                    the entire amount paid not to exceed the total paid for the original
                    reservation.
                </p>
                <p>
                    As a rule of thumb, cancellations to reservations should be made as
                    far in advance of the reservation as possible. Some campgrounds are
                    extremely remote and/or are unstaffed, and last-minute changes may
                    result in challenges in accommodating your changes by either local
                    staff or the contact center.
                </p>
                <p>
                    At staffed facilities, if you need to cancel your reservation after
                    12:00am (midnight) of the facility&apos;s local time on the day before
                    your check-in date (“late cancellation”), on-site personnel sometimes
                    may not be around to assist you, depending on the hour. The
                    Recreation.gov contact center can assist with performing a late
                    cancellation as long as it is not on the day of check-in, where many
                    facilities have rules restricting the contact center from making
                    last-minute changes. However, once the check-in date has started,
                    modifications and cancellations to your reservation will depend on the
                    facility&apos;s unique business rules. If their rules allow for
                    same-day modifications or cancellations, you will have the ability to
                    modify/cancel on your Recreation.gov or mobile app. A contact center
                    agent can also assist in these cases. However, if the facility&apos;s
                    business rules do not allow for same-day modifications or
                    cancellations, then the modify/cancel options will not be selectable
                    on your Recreation.gov account and you must work with a local facility
                    host, attendant, or manager to make changes. The contact center will
                    also not be able to assist you in those circumstances.
                </p>
            </TextBlock>
            <Heading headingLevel={2} appearance="h4">
                Cabins / Lookouts / Yurts
            </Heading>
            <TextBlock width="xl">
                <p>
                    Customers who cancel a cabin, lookout, or yurt reservation{' '}
                    <strong>less than 14 days before</strong> the arrival date (a “late
                    cancellation”) will pay a{' '}
                    <strong>
                        $10.00 late cancellation fee AND forfeit the first night&apos;s
                        fee
                    </strong>
                    .
                </p>
                <p>
                    Late cancellations of a one-night reservation will forfeit the entire
                    amount paid not to exceed the total paid for the original reservation.
                </p>
            </TextBlock>
            <Heading headingLevel={2} appearance="h4">
                Group Facilities: Overnight
            </Heading>
            <TextBlock width="xl">
                <p>
                    Customers who cancel a group overnight facility reservation{' '}
                    <strong>less than 14 days before</strong> the arrival date will pay a{' '}
                    <strong>
                        $10.00 late cancellation fee AND forfeit the first night&apos;s
                        fees
                    </strong>
                    .
                </p>
                <p>
                    Late cancellation of a one-night reservation will forfeit the entire
                    amount paid not to exceed the total paid for the original reservation.
                </p>
            </TextBlock>
            <Heading headingLevel={2} appearance="h4">
                Group Facilities: Day-Use
            </Heading>
            <TextBlock width="xl">
                <p>
                    Customers who cancel a group day-use facility reservation less than 14
                    days before the arrival date will{' '}
                    <strong>forfeit the total day-use fees.</strong>
                </p>
            </TextBlock>
            <Heading headingLevel={2} appearance="h4">
                Changes or Cancellations within the Cut-off Window
            </Heading>
            <TextBlock width="xl">
                <p>
                    Once a reservation date begins, customers cannot make a change using
                    either the online system or through the contact center unless the
                    location allows mid-stay modifications (see below for early
                    departures). Many remote locations, such as those managed by the U.S.
                    Forest Service, are not staffed and reservations cannot be managed
                    on-site. Even if the location is staffed, on-site personnel may or may
                    not be able to assist with changes or cancellations.
                </p>
            </TextBlock>

            <Heading headingLevel={2} appearance="h4">
                Scan & Pay First-Come First-Served Payments
            </Heading>
            <TextBlock width="xl">
                <p>
                    There are no refunds, modifications, cancellations, or other fee
                    changes allowed with Scan & Pay transactions. This Scan & Pay system
                    is designed to operate the same way as putting cash into a fee tube
                    and cannot be extracted once it is inserted into a fee tube.
                </p>
            </TextBlock>

            <Heading headingLevel={2} appearance="h3" className="mb-2" id={modifyAnchor}>
                Modifying/Changing an Existing Reservation Prior to the Cut Off Window
            </Heading>
            <ModifyReservationTable />
            <TextBlock width="xl">
                <p>
                    If a customer wants to switch dates that are entirely outside of the
                    original reservation dates, there is a <strong>$10 change fee</strong>
                    .
                </p>

                <p>
                    At facilities that do not allow &quot;mid-stay changes&quot; (also
                    known as in-flight modifications, which are changes on or after the
                    start date) customers and contact center agents cannot modify
                    reservations on or after the check-in date. For these locations, only
                    facility staff can make those modifications. At facilities that do
                    allow &quot;mid-stay changes&quot;, customers can self-modify their
                    reservation through their Recreation.gov account online or in the
                    mobile app, or reach the contact center for assistance.
                </p>

                <p>
                    There is no change fee if a customer extends or shortens a reservation
                    if the change includes dates from the original reservation. If they
                    choose to depart early, they may forfeit some or all of the fees.
                </p>
                <p>
                    There is no change fee if the customer changes campsites that are the
                    same price and on the same reservation dates in the same campground.
                    If the campsite the customer is changing to has a price difference,
                    the customer will be responsible for any additional fees or be
                    provided with a refund, based on the price difference.
                </p>

                <p>
                    For reservations that include dates beyond the end of the available
                    booking window, you are not able to make changes or cancel your
                    reservation until four days after the site is available to the general
                    public. For more information, please read the{' '}
                    <a href="https://recreationonestopprod.servicenowservices.com/external?id=kb_article&sys_id=9ea670211b099090201beb9ce54bcb06&table=">
                        No Modification Rule for Reserved Nights Beyond the Booking Window
                    </a>{' '}
                    Help Center article.
                </p>
            </TextBlock>
            <Heading headingLevel={2} appearance="h3" className="mb-2" id={noShowAnchor}>
                No-Shows
            </Heading>
            <TextBlock width="xl">
                <p className="mb-2">
                    A no-show customer is one who does not arrive at a campground and does
                    not cancel the reservation by check-out time on the day after the
                    scheduled arrival date (or for day-use facilities, by check-in time
                    the day of arrival).
                </p>

                <ul className="mb-2">
                    <li>
                        No-show customers are only recorded at staffed facilities. At most
                        remote facilities, all customers are noted as automatically
                        checked-in on the date of arrival.
                    </li>
                    <li>
                        It is recommended that staffed facilities hold a campsite until
                        check-out time on the day following the arrival date.
                    </li>
                    <li>
                        It is recommended that staffed facilities hold group day-use
                        facilities until check-in time on the arrival date.
                    </li>
                </ul>

                <p>
                    There is no change fee if a customer extends or shortens a reservation
                    if the change includes dates from the original reservation. If they
                    choose to depart early, they may forfeit some or all of the fees.
                </p>
                <p>
                    There is no change fee if the customer changes campsites that are the
                    same price and on the same reservation dates in the same campground.
                    If the campsite the customer is changing to has a price difference,
                    the customer will be responsible for any additional fees or be
                    provided with a refund, based on the price difference.
                </p>
                <p>
                    Where recorded, no-show customers are assessed a{' '}
                    <strong>
                        $20.00 no-show fee and forfeit the first night&apos;s fees
                    </strong>{' '}
                    for a campsite or <strong>forfeit the entire day-use fees</strong> for
                    a day-use facility. We recommend customers contact the facility if
                    they are en route to their reservation but cannot make it by the
                    check-in date. In these cases, no refund will be issued, but the
                    facility can ensure that a &quot;no-show&quot; fee is not assessed.
                </p>
            </TextBlock>
            <Heading
                headingLevel={2}
                appearance="h3"
                className="mb-2"
                id={departuresAnchor}
            >
                Early Departures
            </Heading>
            <TextBlock width="xl">
                <p>
                    At staffed facilities, if a customer departs prior to their
                    reservation&apos;s scheduled check-out date, they may be eligible for
                    a partial refund depending on whether the facility is able to mark and
                    make the site available to new visitors. Please remember that
                    reservations at these facilities prevent other visitors from using
                    these sites and so early departures may not be eligible for refunds.
                </p>
                <p>
                    Please notify staff at the facility if you depart early to allow
                    others to use the site.
                </p>
                <p>
                    If customers request an early departure after the facility check-out
                    time, the customer will not receive a refund for that night.
                </p>
            </TextBlock>

            <Heading headingLevel={2} appearance="h3" className="mb-2" id={dueNowAnchor}>
                Due Now Payments
            </Heading>
            <TextBlock width="xl">
                <p>
                    This fee is associated with a reservation at a facility that offers a
                    monthly payment plan, better known as Long Term Stays. This is a
                    one-time, non-refundable fee and a portion of the reservation cost
                    charged at the time of booking to secure the full stay dates. The
                    remaining balance will be due 30 days ahead of arrival and can be paid
                    at any time after booking. If the first full month&apos;s balance is
                    not paid by that time, the reservation will be cancelled, and the
                    dates will be released for others to book.
                </p>
                <p>
                    <strong>Cancellations:</strong> The Due Now fee set by the managing
                    agency and is non-refundable. Customers can cancel at any point prior
                    to the reservation start date. If the Due Now amount is less than the
                    first night&apos;s use fee, customers will incur a charge of the first
                    night&apos;s use fee plus a $10 cancellation fee. If the Due Now
                    amount is greater than the first night&apos;s use fee, cancellations
                    will not be charged a cancellation fee.
                </p>
                <p className="mb-0">
                    <strong>Modifications:</strong>
                </p>
                <ul className="mt-1">
                    <li>
                        If a customer wants to switch dates that are entirely outside of
                        the original reservation dates, there is a{' '}
                        <strong>$10 change fee</strong>.
                    </li>
                    <li>
                        Facility must have &quot;mid-stay changes&quot; (also known as
                        in-flight modifications, which are changes on or after the start
                        date) turned on to allow modifications after the arrival date.
                    </li>
                </ul>
                <p>
                    <strong>No Shows:</strong> No shows will incur a $20 no-show fee and
                    forfeit the Due Now and the first night&apos;s use fee.
                </p>
                <p className="mb-0">
                    <strong>Early Departures:</strong>
                </p>
                <ul className="mt-1 mb-6">
                    <li>
                        At staffed facilities, if a customer departs prior to their
                        reservation&apos;s scheduled check-out date, they may be eligible
                        for a partial refund depending on whether the facility is able to
                        mark and make the site available to new visitors. Please remember
                        that reservations at these facilities prevent other visitors from
                        using these sites and so early departures may not be eligible for
                        refunds.
                    </li>
                    <li>
                        Please notify staff at the facility if you depart early to allow
                        others to use the site.
                    </li>
                    <li>
                        If customers request an early departure after the facility
                        check-out time, the customer will not receive a refund for that
                        night.
                    </li>
                </ul>
            </TextBlock>

            <Heading
                headingLevel={2}
                appearance="h3"
                className="mb-2"
                id={downPaymentAnchor}
            >
                Down Payment
            </Heading>
            <TextBlock width="xl">
                <p>
                    This non-refundable fee is determined by the total number of nights
                    reserved and is a portion of the reservation cost charged at the time
                    of booking to secure the full stay dates. The remaining balance will
                    be due 30 days ahead of arrival and can be paid at any time after
                    booking. If the remaining balance is not paid by that time, the
                    reservation will be cancelled, and the dates will be released for
                    others to book.
                </p>
                <p>
                    <strong>Cancellations:</strong> The Down Payment fee is set by the
                    managing agency and is non-refundable. Down Payments are charged as a
                    nightly rate. Customers can cancel at any point prior to the
                    reservation start date. Customer is charged Down Payment in full plus
                    a $10 cancellation fee.
                </p>
                <p className="mb-0">
                    <strong>Modifications:</strong>
                </p>
                <ul className="mt-1">
                    <li>
                        If a customer wants to switch dates that are entirely outside of
                        the original reservation dates, there is a{' '}
                        <strong>$10 change fee</strong>.
                    </li>
                    <li>
                        Facility must have &quot;mid-stay changes&quot; (also known as
                        in-flight modifications, which are changes on or after the start
                        date) turned on to allow modifications after the arrival date.
                    </li>
                    <li>
                        If removing nights from the reservation, customer will not be
                        refunded the Down Payment amount for the nights removed.
                    </li>
                </ul>
                <p>
                    <strong>No Shows:</strong> No shows will incur a $20 no-show fee and
                    forfeit the Down Payment and the first night&apos;s use fee.
                </p>
            </TextBlock>
        </section>
    );
}
