/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as React from 'react';
import { Heading } from '@fp/sarsaparilla';
import SearchFilterAgencies from '../../SearchFilterAgencies';
import SearchFilterActivities from '../../SearchFilterActivities';
import { getCount } from '../../../utils/filters';
import { AgreggationProps, UiFiltersProps } from '../../../types';

export type AdditionalPreferencesProps = {
    aggregations: AgreggationProps;
    uiFilters: UiFiltersProps;
    onFilterToggle: (v: string, k: any) => void;
    onApplyFilters: () => void;
};

function AdditionalPreferences({
    aggregations,
    uiFilters,
    onFilterToggle,
    onApplyFilters,
}: AdditionalPreferencesProps) {
    const additionalPreferencesCount = getCount(['agencies', 'activities'], uiFilters);
    return (
        <div className="mb-4">
            <div className="search-filters-section-header rounded-layout mb-1 mt-4">
                <Heading headingLevel={2} appearance="h5">
                    Additional Preferences.
                </Heading>
                <span>
                    {additionalPreferencesCount}{' '}
                    {additionalPreferencesCount > 1 || additionalPreferencesCount === 0
                        ? 'Filters'
                        : 'Filter'}{' '}
                    Selected
                </span>
            </div>
            <div className="search-filters-filter-container pt-0">
                {process.env?.SEARCH?.showFederalAgencies && (
                    <SearchFilterAgencies
                        uiFilters={uiFilters}
                        aggregations={aggregations}
                        onFilterToggle={onFilterToggle}
                        onApplyFilters={onApplyFilters}
                        expandable
                    />
                )}

                <SearchFilterActivities
                    uiFilters={uiFilters}
                    aggregations={aggregations}
                    onFilterToggle={onFilterToggle}
                    onApplyFilters={onApplyFilters}
                    expandable
                />
            </div>
        </div>
    );
}

export default AdditionalPreferences;
