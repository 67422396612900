/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, FlexCol, FlexRow, Icons, Alert } from 'sarsaparilla';

function LayersPanelButton({
    hasTrails,
    showPanel,
    setShowPanel,
    displayTrailsMessage,
    setDisplayTrailsMessage,
}) {
    const onButtonClick = () => {
        setShowPanel(!showPanel);
        if (displayTrailsMessage) setDisplayTrailsMessage(false);
    };

    const layerButton = (
        <Button
            iconBeforeElement={<Icons.IconLayers />}
            className="map-layer-button"
            appearance="subtle"
            onClick={onButtonClick}
            gaTrackingId="073068139208"
        >
            Map Layers
        </Button>
    );

    const getLayerNotificationMessage = (message) => (
        <div className="layers-notification-message-container">
            <div className="layers-notification-message-triangle" />
            <div className="layers-notification-message-box">{message}</div>
        </div>
    );

    const trailsMessage = getLayerNotificationMessage(
        'New! Click here to show Trails...'
    );

    return (
        <>
            {hasTrails && displayTrailsMessage && trailsMessage}
            {layerButton}
        </>
    );
}

export default LayersPanelButton;

LayersPanelButton.propTypes = {
    hasTrails: PropTypes.bool,
    showPanel: PropTypes.bool,
    setShowPanel: PropTypes.func,
    displayTrailsMessage: PropTypes.bool,
    setDisplayTrailsMessage: PropTypes.func,
};
