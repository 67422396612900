/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { entries } from 'lodash';
import PropTypes from 'prop-types';
import { toTitleCase, Icons, Button } from 'sarsaparilla';
import { CAMPING_TYPED_ICONS } from '../helpers/utils';
import { AMENITIES, SITE_STATUS, SITE_TYPES } from '../constants';

function CampingLegendControl(props) {
    const renderLegends = (legends, title, handleClick) => {
        const isClickable = typeof handleClick === 'function';
        return (
            <ul className={`legend-section ${isClickable ? 'with-buttons' : ''}`}>
                {!!title && (
                    <li
                        key={`${title.replace(/\s+/g, '-')}-segment`}
                        className="legend-section-title"
                    >
                        {title}
                    </li>
                )}
                {entries(legends).map(([iconName, displayNames]) => {
                    const Icon = Icons?.[`Icon${iconName}`];
                    const displayNamesArray = Array.from(displayNames);
                    const listItem = () => {
                        return (
                            <>
                                <Icon />
                                <span>{displayNamesArray?.join(', ')}</span>
                            </>
                        );
                    };

                    return (
                        <li key={iconName}>
                            {isClickable ? (
                                <Button
                                    isUnstyled="true"
                                    onClick={() => handleClick(displayNamesArray)}
                                    gaTrackingId={918330349826}
                                >
                                    {listItem()}
                                </Button>
                            ) : (
                                listItem()
                            )}
                        </li>
                    );
                })}
            </ul>
        );
    };

    const populateLegendsObject = (legends, key, value) => {
        const nextLegends = { ...legends };

        nextLegends[key] = nextLegends[key] ?? new Set();
        nextLegends[key].add(value);

        return nextLegends;
    };

    const renderCampsiteLegends = () => {
        if (!props?.campsites?.features?.length) return null;

        const campsiteTypes = props.campsites.features.map(
            (feat) => feat?.properties?.campsite_type
        );
        let legends = {};

        campsiteTypes.forEach((campsiteType) => {
            const iconName = CAMPING_TYPED_ICONS[campsiteType];
            const displayName = toTitleCase(campsiteType);

            legends = populateLegendsObject(legends, iconName, displayName);
        });

        return renderLegends(legends, SITE_TYPES);
    };

    const renderAmenitiesLegends = () => {
        if (!props?.amenities?.features?.length) return null;

        const amenitiesProperties = props?.amenities?.features?.map(
            (feat) => feat?.properties
        );
        let legends = {};

        amenitiesProperties.forEach((property) => {
            const { icon, name } = property;
            const dirtyIconStr = icon.replace(/rec-icon-/, '');
            const titleCaseIconStr = toTitleCase(dirtyIconStr);
            const iconName = titleCaseIconStr.replace(/-/g, '');

            legends = populateLegendsObject(legends, iconName, name);
        });

        return renderLegends(legends, AMENITIES, props.setSelectedLegendFeature);
    };

    const renderCampsiteStatusLegend = () => {
        const statuses = [
            {
                iconName: <span className="full-circle-availability circle-available" />,
                displayNames: 'Available for selected dates',
                key: 'available',
            },
            {
                iconName: (
                    <span className="full-circle-availability circle-not-reservable" />
                ),
                displayNames: 'First-Come, First-Serve site',
                key: 'not-reservable',
            },
            {
                iconName: <span className="half-circle-availability" />,
                displayNames: 'Partially available or no dates',
                key: 'partially-available',
            },
            {
                iconName: (
                    <span className="full-circle-availability circle-unavailable" />
                ),
                displayNames: 'Unavailable',
                key: 'unavailable',
            },
        ];

        return (
            <ul className="legend-section">
                <li key="status-segment" className="legend-section-title">
                    {SITE_STATUS}
                </li>

                {statuses.map((status) => {
                    return (
                        <li key={status.key} className="availability-status-li">
                            <span className="availability-status-icon">
                                {status.iconName}
                            </span>
                            <span>{status.displayNames}</span>
                        </li>
                    );
                })}
            </ul>
        );
    };

    return (
        <>
            {renderCampsiteStatusLegend()}
            {renderCampsiteLegends()}
            {renderAmenitiesLegends()}
        </>
    );
}

CampingLegendControl.propTypes = {
    campsites: PropTypes.object,
    amenities: PropTypes.object,
};

export default CampingLegendControl;
