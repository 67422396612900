/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom-v5-compat';

import {
    Badge,
    ContentBlock,
    TextBlock,
    Box,
    Stack,
    DividerNew as Divider,
    Alert,
    ReadMoreBlock,
    DisplayHtmlContent,
    useWindowSize,
    Icons,
    FlexRow,
    FlexCol,
    Button,
} from 'sarsaparilla';

const htmlTagPattern = /<[^>]*>/g;
const htmlClosingTagPattern = /<\/[^>]*>/g;

const htmlWithoutBasicTagsTagPattern = /<[^a|strong^/][^>]*>/g;
const htmlClosingWithoutBasicTagsTagPattern = /<\/[^a|strong][^>]*>/g;

export default function FacilityAlertBanner({ alerts, allowBasicTags = true, ...rest }) {
    const [isExpanded, setExpanded] = useState(false);
    const { width } = useWindowSize(200);
    const { pathname } = useLocation();
    const currentPage = pathname;

    let topAlertIsTrimmed = false;

    if (!alerts || !alerts.length) {
        return null;
    }

    const topAlertBody = alerts[0].body;
    let topAlertText;

    if (allowBasicTags) {
        topAlertText = topAlertBody
            .replace(htmlWithoutBasicTagsTagPattern, ' ')
            .replace(htmlClosingWithoutBasicTagsTagPattern, '');
    } else {
        topAlertText = topAlertBody
            .replace(htmlClosingTagPattern, ' ')
            .replace(htmlTagPattern, '');
    }

    // Set the max character count for the alert
    let previewTextLength = 90;

    if (width >= 768) {
        previewTextLength = 148;
    }

    if (width >= 992) {
        previewTextLength = 220;
    }

    if (width >= 1300) {
        previewTextLength = 264;
    }

    if (topAlertText.length > previewTextLength) {
        topAlertIsTrimmed = true;
        topAlertText = topAlertText.slice(0, previewTextLength);
        topAlertText.trim();
        topAlertText += '...';
    }

    const renderAllAlerts = () => {
        return (
            <Box marginY="sm">
                <Stack space="md">
                    {alerts.map((item, index) => (
                        <React.Fragment key={item.id}>
                            <Alert hasBoxStyling={false}>
                                <ReadMoreBlock
                                    linesToShow={6}
                                    buttonAriaLabel={`Read more: Alert #${index + 1}`}
                                >
                                    <DisplayHtmlContent html={item.body} />
                                </ReadMoreBlock>
                            </Alert>
                            {index !== alerts.length - 1 && <Divider color="yellow" />}
                        </React.Fragment>
                    ))}
                </Stack>
            </Box>
        );
    };

    const topAlert = (
        <Alert hasBoxStyling={false}>
            <DisplayHtmlContent html={topAlertText} />
        </Alert>
    );
    const showAllAlertsButton = alerts.length > 1 || topAlertIsTrimmed;

    let expanderButtonTitle = '';
    let clickTagActionText = '';

    if (alerts?.length > 1) {
        if (isExpanded) {
            expanderButtonTitle = (
                <React.Fragment>
                    Hide all alerts <Icons.IconChevronUp />
                </React.Fragment>
            );
            clickTagActionText = 'Close Expanded Alerts';
        } else {
            expanderButtonTitle = (
                <React.Fragment>
                    Show all alerts <Icons.IconChevronDown />
                </React.Fragment>
            );
            clickTagActionText = 'Expand Alerts';
        }
    } else if (alerts?.length === 1) {
        if (isExpanded) {
            expanderButtonTitle = (
                <React.Fragment>
                    Hide alert <Icons.IconChevronUp />
                </React.Fragment>
            );
            clickTagActionText = 'Close Expanded Alert';
        } else {
            expanderButtonTitle = (
                <React.Fragment>
                    Show alert <Icons.IconChevronDown />
                </React.Fragment>
            );
            clickTagActionText = 'Expand Alert';
        }
    }

    return (
        <Box
            paddingY="xs"
            background="light-yellow"
            data-shared-facility-alert-banner
            {...rest}
        >
            <ContentBlock>
                <FlexRow>
                    <FlexCol xs={12} lg={10} lgOffset={1} xxl={8} xxlOffset={2}>
                        <TextBlock width="xl">
                            {isExpanded ? renderAllAlerts() : topAlert}
                        </TextBlock>
                    </FlexCol>
                    <FlexCol xs={12} className="shared-fab-show-all-button-wrap">
                        {showAllAlertsButton && (
                            <Button
                                isUnstyled
                                className="shared-fab-show-all-button"
                                onClick={() => setExpanded((prev) => !prev)}
                                clickTagCategory="Facility Alert Banner Toggle"
                                clickTagAction={`${clickTagActionText}`}
                                clickTagLabel={`Facility Alert Banner on ${currentPage}`}
                            >
                                <div>
                                    <Badge className="alert-badge-total-items">
                                        {alerts.length}
                                    </Badge>
                                    <span className="shared-fab-button-copy-wrap">
                                        {expanderButtonTitle}
                                    </span>
                                </div>
                            </Button>
                        )}
                    </FlexCol>
                </FlexRow>
            </ContentBlock>
        </Box>
    );
}

FacilityAlertBanner.propTypes = {
    alerts: PropTypes.arrayOf(
        PropTypes.shape({
            body: PropTypes.string,
            id: PropTypes.string,
        })
    ),
    allowBasicTags: PropTypes.bool,
};
