/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { Button, Icons, ImageUnavailable } from 'sarsaparilla';
import StarDisplay from '../../../ui-rating-review/src/components/StarDisplay';
import { createComponent, handleClosePopUp } from './common';

const tripPlannerMapPopup = (asset) => {
    const { campsite_type_of_use, type, entity_type, availability, availability_counts } =
        asset;
    const isDayUse =
        campsite_type_of_use?.length === 1 && campsite_type_of_use?.[0] === 'Day';
    const perUnit = isDayUse ? 'per day' : 'per night';

    let availabilityList = type === 'CAMPING_LOTTERY' ? <li>Lottery</li> : null;
    if (availability && entity_type === 'campground') {
        let availabilityContent =
            availability === 'partially available'
                ? 'Partially Available'
                : 'Unavailable';
        if (availability === 'available') {
            const isNotReservable =
                Object.keys(availability_counts || []).length === 1 &&
                availability_counts?.['Not Reservable'] >= 0;
            availabilityContent = isNotReservable
                ? 'First-come, First-served'
                : 'Sites Available';
        }

        availabilityList = <li>{availabilityContent}</li>;
    }

    const buttonText = asset.isInItinerary ? 'Remove from itinerary' : 'Add to itinerary';

    const popupContent = (
        <div className="map-popup-trip-planner">
            <div>
                <Button
                    className="close-pop-up-btn"
                    appearance="subtle"
                    size="sm"
                    shouldFitContainer={false}
                    onClick={handleClosePopUp}
                    iconBeforeElement={<Icons.IconClose />}
                    screenReaderTextBefore="Close the modal"
                    gaTrackingId="681762123001"
                />
                <div className="map-popup-image">
                    {asset.imageUrl ? (
                        <img src={asset.imageUrl} alt={asset.imageTitle} />
                    ) : (
                        <ImageUnavailable />
                    )}
                </div>
                <div className="map-popup-message">
                    <span className="h4 map-popup-title">{asset.title}</span>
                    {asset.location && (
                        <p className="map-popup-text">Near {asset.location}</p>
                    )}
                    {asset.description && (
                        <p className="map-popup-text">{asset.description}</p>
                    )}

                    <ul className="map-popup-location-count">
                        {asset.attributes &&
                            asset.attributes.map((attribute) => (
                                <li key={attribute.key}>{attribute.value}</li>
                            ))}
                        {asset.rate && (
                            <li key={`rate-${asset.rate}`}>
                                ${asset.rate} {perUnit}
                            </li>
                        )}
                        {asset.reservable && asset.campsitesCount && (
                            <li key={`campsite-count-${asset.campsitesCount}`}>
                                {asset.campsitesCount} sites
                            </li>
                        )}
                        {asset.toursCount && (
                            <li key={`tour-count-${asset.toursCount}`}>
                                {asset.toursCount} tours
                            </li>
                        )}
                        {asset.number_of_ratings && asset.number_of_ratings > 0 && (
                            <div className="rating-info">
                                <StarDisplay value={asset.average_rating} />(
                                {asset.number_of_ratings})
                            </div>
                        )}
                        {availabilityList}
                        <li key="rec-site-icons-wrap" className="rec-site-icons-wrap">
                            <Icons.IconTrailerHookup className="rec-icon" />
                            <Icons.IconElectricity15 className="rec-icon" />
                            <Icons.IconPet className="rec-icon" />
                            <Icons.IconTent className="rec-icon" />
                            <Icons.IconActivityWater className="rec-icon" />
                        </li>
                    </ul>
                </div>
            </div>

            <div className="popup-button-wrap">
                {asset.isTripEditable && (
                    <div className="map-popup-button">
                        <Button
                            className="rec-button-tertiary"
                            appearance="tertiary"
                            aria-label={buttonText}
                            onClick={() => asset.onClick(asset)}
                            gaTrackingId="883520575024"
                            gaTrackingValue={`${buttonText} - ${asset.title}`}
                        >
                            {buttonText}
                        </Button>
                    </div>
                )}

                <div className="map-popup-button">
                    <Button
                        className="rec-button-primary"
                        appearance="primary"
                        aria-label="View Details"
                        target="_blank"
                        onClick={() => asset.onDetailsClick(asset)}
                        gaTrackingId="317756999568"
                        gaTrackingValue={asset.title}
                    >
                        View Details
                    </Button>
                </div>
            </div>
        </div>
    );

    return createComponent(popupContent);
};

export default tripPlannerMapPopup;
