/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Box, Icons, Placeholder, PlaceholderText } from 'sarsaparilla';

export function IconDetailBlock({ iconName, label, value, isDimmed, isLoading }) {
    if (isLoading) {
        return <IconDetailBlockPlaceholder />;
    }
    if (!label || !value || !iconName) {
        return null;
    }

    const Icon = Icons[iconName];

    return (
        <Box
            data-shared-icon-detail-block
            border="gray"
            alignX="left"
            className={cx({
                dimmed: isDimmed,
            })}
        >
            <Box
                alignY="center"
                alignX="center"
                padding={['xs', 'xs', 'sm']}
                data-shared-detail-block-icon
            >
                <Icon size="md" />
            </Box>
            <Box padding={['xs', 'xs', 'sm']} alignY="center">
                <div>
                    <strong>{`${label}: `}</strong>
                    <span>{value}</span>
                </div>
            </Box>
        </Box>
    );
}

IconDetailBlock.propTypes = {
    iconName: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    isDimmed: PropTypes.bool,
    isLoading: PropTypes.bool,
};

export function IconDetailBlockPlaceholder() {
    return (
        <Box data-shared-icon-detail-block alignX="left">
            <Box alignY="center" alignX="center" padding={['xs', 'xs', 'sm']}>
                <Placeholder width="24px" height="24px" />
            </Box>
            <Box padding={['xs', 'xs', 'sm']} alignY="center">
                <div>
                    <PlaceholderText width="150px" />
                </div>
            </Box>
        </Box>
    );
}
