/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/**
 * BasicMap is a simple template to
 * render a single data source.
 * Currently supports only point data
 */

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { usePDF } from '@react-pdf/renderer';
import { services } from '../constants';
import { downloadMapImageFile, updateMapImage } from './common';
import MapPDFDocument from './mapPDFDocument';
import { createDownloadControl } from '../controls/commonControls';
import { getSpecialControlsPerService } from '../controls/controls';

function useMapPDF(props = {}) {
    const mapImageRef = useRef(null);
    const isButtonCreated = useRef(false);
    const isEventAdded = useRef(false);

    const [mapImage, setMapImage] = useState(null);
    const [shouldDownload, setShouldDownload] = useState(false);

    const mapPDFDoc = (
        <MapPDFDocument
            image={mapImage}
            title={services[props.service]?.pdfTitle}
            subtitle={props.featureName?.toUpperCase()}
        />
    );
    const [mapPDF, updateMapPDF] = usePDF({ document: mapPDFDoc });

    useEffect(() => {
        if (props.basicControls?.current?.downloadControl && !isButtonCreated.current) {
            const downloadProps = { onClick: () => setShouldDownload(true) };
            const hasLegend =
                getSpecialControlsPerService(props.service)?.legend || props.hasLegend;
            const className = `download-button-container${hasLegend ? '' : '-no-legend'}`;
            props.basicControls.current.downloadControl.addElement(
                createDownloadControl(downloadProps),
                className
            );
        }
    }, [props.basicControls?.current]);

    useEffect(() => {
        updateMapPDF(mapPDFDoc);
    }, [mapImage]);

    useEffect(() => {
        if (!shouldDownload) return;
        downloadMapImageFile({ data: mapPDF.blob });
        setShouldDownload(false);
    }, [shouldDownload]);

    useEffect(() => {
        if (!props.map?.current || isEventAdded.current) return;
        isEventAdded.current = true;
        props.map.current.on('load', () => {
            props.map.current.on('idle', () => {
                const updateParams = {
                    map: props.map,
                    mapImageRef,
                    setMapImage,
                };
                updateMapImage(updateParams);
            });
        });
    }, [props.map?.current]);

    return mapPDF;
}

useMapPDF.propTypes = {
    service: PropTypes.string,
    featureName: PropTypes.string,
    basicControls: PropTypes.object,
    map: PropTypes.object,
};

export default useMapPDF;
