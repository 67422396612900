/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/**
 * Takes a gatewayId (also sometimes called assetId) and returns the correct path to the gateway page based on the tenant config
 */
export function getGatewayUrl(gatewayId: string | null | undefined) {
    if (!gatewayId) return undefined;

    return process.env.GATEWAY_VERSION === 'v2'
        ? `/gateways/${gatewayId}`
        : `/camping/gateways/${gatewayId}`;
}
