/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import {
    Icons,
    StringHelper,
    SearchActions,
    InventoryTag,
    MapHelper,
    Button,
} from 'sarsaparilla';
import { debounce } from 'lodash';
import * as actions from '../actions/search';

const theme = {
    container: '',
    containerOpen: '',
    input: '',
    inputOpen: '',
    inputFocused: '',
    suggestionsContainer: 'search-suggestions-container',
    suggestionsContainerOpen: 'open',
    suggestionsList: 'search-suggestions-section-suggestions-container',
    suggestion: 'search-suggestions-suggestion-wrapper',
    suggestionFirst: 'first',
    suggestionHighlighted: 'search-suggestion-focused',
    sectionContainer: 'search-suggestions-section-container',
    sectionContainerFirst: 'first',
    sectionTitle: 'search-suggestions-section-title',
};
const TEXT_SEARCH_NEAR_ME = 'Search for places or activities near me';
const TEXT_CLEAR_SEARCH_HIST = 'Clear Search History';

// SearchInputContainer is search input box and autosuggestions combo
class SearchInputContainer extends React.Component {
    static propTypes = {
        placeholder: PropTypes.string,
        dispatch: PropTypes.func,
        updateSearchCriterias: PropTypes.func,
        navigateToSearchResults: PropTypes.func,
        fetchSuggestions: PropTypes.func,
        disableSearchOnSelect: PropTypes.bool,
        queryCriteriaPropertyName: PropTypes.string,
        onFocus: PropTypes.func,
        onRunSearch: PropTypes.func,
        onBlur: PropTypes.func,
        search: PropTypes.object,
        searchSuggestions: PropTypes.shape({
            suggestions: PropTypes.array,
            inventory_suggestions: PropTypes.array,
            content_suggestions: PropTypes.array,
        }),
    };

    static defaultProps = {
        placeholder: 'Where to?',
        disableSearchOnSelect: false,
        queryCriteriaPropertyName: 'what',
        updateSearchCriterias: SearchActions.updateSearchCriterias,
        navigateToSearchResults: SearchActions.navigateToSearchResults,
        fetchSuggestions: SearchActions.fetchSuggestions,
        searchSuggestions: PropTypes.shape({
            suggestions: [],
            inventory_suggestions: [],
            content_suggestions: [],
        }),
    };

    constructor() {
        super();

        this.state = {
            searchText: '', //params.search[params.queryCriteriaPropertyName],
            showSuggestions: true,
            searchHistory: [],
        };
        this.highlightedSuggestion = null;
        this.selectedSuggestion = null;

        this.onChange = this.onChange.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onSuggestionsFetchRequested = debounce(
            this.onSuggestionsFetchRequested.bind(this),
            200,
            { leading: true, trailing: true }
        );
        this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
        this.getSectionSuggestions = this.getSectionSuggestions.bind(this);
        this.getSuggestionValue = this.getSuggestionValue.bind(this);
        this.renderSectionTitle = this.renderSectionTitle.bind(this);
        this.renderSuggestion = this.renderSuggestion.bind(this);
        this.shouldRenderSuggestions = this.shouldRenderSuggestions.bind(this);
        this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
        this.onSuggestionHighlighted = this.onSuggestionHighlighted.bind(this);
        this.renderInputComponent = this.renderInputComponent.bind(this);
        this.renderSuggestionsContainer = this.renderSuggestionsContainer.bind(this);
        this.runSearch = this.runSearch.bind(this);
        this.loadHistory = this.loadHistory.bind(this);
    }

    async loadHistory() {
        try {
            const loadedHistory = await SearchActions.loadSearchHistoryItems();
            this.setState({
                searchHistory: loadedHistory,
            });
        } catch (e) {
            // ignore errors
            console.log('error loading history', e);
        }
    }

    componentDidUpdate(prevProps) {
        const { search } = this.props;

        if (search !== prevProps.search) {
            // listen for changes in search input query string and update itself
            const nextWhat = search[this.props.queryCriteriaPropertyName];
            if (
                !nextWhat ||
                (nextWhat &&
                    nextWhat !== '' &&
                    (!this.state.searchText ||
                        this.state.searchText === '' ||
                        nextWhat !== this.state.searchText))
            ) {
                this.setState({ searchText: nextWhat });
            }
        }
    }

    // on suggestion value change (on keypress etc)
    onChange(event, { newValue }) {
        if (newValue === TEXT_SEARCH_NEAR_ME || newValue === TEXT_CLEAR_SEARCH_HIST) {
            return;
        }
        this.setState({ searchText: newValue }, () => {
            const updateParams = {};
            updateParams[this.props.queryCriteriaPropertyName] = newValue;
            this.props.dispatch(this.props.updateSearchCriterias(updateParams));
        });
    }

    // when auto suggests needs more data
    onSuggestionsFetchRequested({ value }) {
        if (value && value.length > 0) {
            this.props.dispatch(this.props.fetchSuggestions(value));
        }
    }

    // when suggestions clear
    onSuggestionsClearRequested() {
        // show something by default for empty?
    }

    // when auto suggestion was selected somehow using navs
    async onSuggestionSelected(event, { suggestion }) {
        if (suggestion.text === TEXT_SEARCH_NEAR_ME) {
            this.selectedSuggestion = null;
            this.setState(
                {
                    showSuggestions: false,
                    searchText: '',
                },
                () => {
                    this.runSearch();
                }
            );
            return;
        }

        if (suggestion.text === TEXT_CLEAR_SEARCH_HIST) {
            this.selectedSuggestion = null;
            try {
                await SearchActions.deleteSearchHistoryItems();
            } catch (e) {
                // ignore errors
                console.log('error deleting history', e);
            }
            this.setState({
                showSuggestions: false,
                searchText: '',
            });
            return;
        }

        const suggestionText = this.getSuggestionValue(suggestion);
        this.highlightedSuggestion = null;
        this.selectedSuggestion = suggestion;

        this.setState(
            {
                searchText: suggestionText,
            },
            () => {
                this.props.dispatch(
                    this.props.updateSearchCriterias({
                        selectedSuggestion: suggestion,
                    })
                );

                this.setState({
                    showSuggestions: false,
                });

                if (!this.props.disableSearchOnSelect) {
                    // run search
                    this.runSearch(suggestion);
                }
            }
        );
    }

    // when auto suggestion was highlighted somehow using navs
    onSuggestionHighlighted({ suggestion }) {
        this.highlightedSuggestion = suggestion;
    }

    // listen for input key in the search/autosuggestion box
    onKeyPress(event) {
        if (event.key === 'Enter') {
            if (this.selectedSuggestion) {
                this.onSuggestionSelected(event, { suggestion: this.selectedSuggestion });
                return;
            }
            // run search if Enter is not on a highlighted suggestion
            if (!this.highlightedSuggestion) {
                this.runSearch(null);
            } else if (this.state.showSuggestions) {
                // hide auto suggestions if auto suggestions are open, but don't run search yet
                this.highlightedSuggestion = null;
                this.setState({
                    showSuggestions: false,
                });
            }
        } else if (this.selectedSuggestion != null || !this.state.showSuggestions) {
            // show auto suggestions on keypress
            this.selectedSuggestion = null;
            this.setState({
                showSuggestions: true,
            });
            this.props.dispatch(
                this.props.updateSearchCriterias({
                    selectedSuggestion: null,
                })
            );
        }
    }

    // on focus search input
    onFocus(event) {
        this.setState({
            showSuggestions: true,
        });
        this.loadHistory();
        if (this.props.onFocus) {
            this.props.onFocus(event);
        }
    }

    // on removal of focus from search input
    onBlur(event) {
        if (this.props.onBlur) {
            this.props.onBlur(event);
        }
    }

    // construct suggestion sections from fetched data
    getSuggestions() {
        const sections = [];

        if (!this.state.searchText || this.state.searchText === '') {
            const sHist = Object.assign([], this.state.searchHistory);
            if (sHist && sHist.length > 0) {
                sections.push({
                    suggestions: [
                        {
                            text: TEXT_SEARCH_NEAR_ME,
                        },
                    ],
                });
                sHist.push({
                    text: TEXT_CLEAR_SEARCH_HIST,
                });
                sections.push({
                    suggestions: sHist,
                    title: 'Recent Searches',
                });
            }
            return sections;
        }

        if (
            this.props.searchSuggestions.inventory_suggestions &&
            this.props.searchSuggestions.inventory_suggestions.length > 0
        ) {
            sections[sections.length] = {
                title: 'Recreation Areas, Facilities, Tours, Trails',
                suggestions: this.props.searchSuggestions.inventory_suggestions,
            };
        }

        if (
            this.props.searchSuggestions.suggestions &&
            this.props.searchSuggestions.suggestions.length > 0
        ) {
            sections[sections.length] = {
                title: 'Locations, City, State, Zip Code',
                suggestions: this.props.searchSuggestions.suggestions,
            };
        }

        if (
            this.props.searchSuggestions.content_suggestions &&
            this.props.searchSuggestions.content_suggestions.length > 0
        ) {
            sections[sections.length] = {
                title: 'Help Topics, Frequently Asked Questions, Articles',
                suggestions: this.props.searchSuggestions.content_suggestions,
            };
        }

        return sections;
    }

    // get search suggestion string to display in the drop down
    getSuggestionValue(suggestion) {
        let suggestionText = suggestion.text;
        if (suggestion.is_inventory) {
            // if suggestion is associated with an inventory name, then capitalize the name to clean it up
            suggestionText = StringHelper.toTitleCase(suggestionText);
        }

        return suggestionText;
    }

    // return suggestions for the given section
    getSectionSuggestions(section) {
        if (section && section.suggestions) {
            return section.suggestions;
        }
        return [];
    }

    // trigger auto suggestion on second char
    shouldRenderSuggestions() {
        // (value) {
        return this.state.showSuggestions; //&& value.trim().length > 0;
    }

    // append q param to target url used in browser redirect for GA site search stats
    appendSearchQueryToTarget(targetUri) {
        const q = `q=${encodeURIComponent(this.state.searchText)}`;
        if (targetUri.includes('?')) {
            return `${targetUri}&${q}`;
        }
        return `${targetUri}?${q}`;
    }

    containsEmail(email) {
        return email?.match(
            // eslint-disable-next-line no-useless-escape
            /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/
        );
    }

    // execute search request to fetch search results
    async runSearch(suggestion) {
        const eventCategory = 'Search';
        let eventAction = 'Submit Search';
        let eventLabel = this.state.searchText;
        if (suggestion) {
            eventAction = 'Select Search Suggestion';
            if (suggestion.last_used) {
                eventAction = 'Select Search History Suggestion';
            }
            eventLabel = suggestion.name || suggestion.text;
        }

        try {
            await SearchActions.addSearchHistoryItem({
                searchHistory: this.state.searchHistory,
                rawSearchText: this.state.searchText,
                suggestion,
            });
        } catch (e) {
            // ignore errors
            console.log('error saving history', e);
        }

        if (this.containsEmail(eventLabel)) {
            eventLabel = '[Redacted Email]';
        }
        const event = {
            category: eventCategory,
            action: eventAction,
            label: eventLabel,
        };
        if (
            suggestion &&
            (suggestion.reservable ||
                suggestion.entity_type === 'recarea' ||
                suggestion.entity_type === 'kb' ||
                suggestion.entity_type === 'cms' ||
                suggestion.entity_type === 'page' ||
                suggestion.entity_type === 'pass')
        ) {
            const url = MapHelper.getTargetUri(suggestion);
            SearchActions.trackRunSearch(event, () => {
                window.location.assign(url);
            });
        } else if (this.props.onRunSearch) {
            SearchActions.trackRunSearch(event, () => {
                this.props.onRunSearch(this.state.searchText, suggestion);
            });
        } else {
            let lat;
            let lng;
            let radius;
            let location;
            let lat_sw;
            let lng_sw;
            let lat_ne;
            let lng_ne;
            if (
                suggestion &&
                !suggestion.entity_type &&
                suggestion.lat &&
                suggestion.lng
            ) {
                // default search to suggestion location, if selected
                lat = suggestion.lat;
                lng = suggestion.lng;
                radius = 200;
                location = suggestion.text;

                // apply only to large states
                if (
                    suggestion.lat_sw &&
                    suggestion.lat_ne &&
                    suggestion.lng_sw &&
                    suggestion.lng_ne
                ) {
                    lat = null;
                    lng = null;
                    lat_sw = suggestion.lat_sw;
                    lng_sw = suggestion.lng_sw;
                    lat_ne = suggestion.lat_ne;
                    lng_ne = suggestion.lng_ne;
                }
            }

            const criteria = {
                what: this.state.searchText,
                headerTextQuery: null,
                filtersVisible: false,
                entity_id: suggestion != null ? suggestion.entity_id : null,
                entity_type: suggestion != null ? suggestion.entity_type : null,
                lat,
                lng,
                radius,
                location,
                map_center_lat: null,
                map_center_lng: null,
                start: null,
                cursor_mark: null,
                placename: null,
                user_placename: null,
                lat_sw,
                lng_sw,
                lat_ne,
                lng_ne,
                parent_asset_id: null,
                org_id: null,
            };
            this.props.dispatch(this.props.updateSearchCriterias(criteria));
            SearchActions.trackRunSearch(event, () => {
                const uri = actions.buildSearchNavUri('/search', this.props);
                history.replaceState({}, '', uri);
                this.setState({
                    showSuggestions: false,
                });
                this.props.dispatch(actions.fetchSearchResults());
            });
        }
    }

    // render suggestion section title
    renderSectionTitle(section) {
        if (section.title) {
            return <span>{section.title}</span>;
        }

        return null;
    }

    getSuggestionIcon(suggestion) {
        if (suggestion.entity_type === 'campground') {
            if (
                suggestion?.campsite_type_of_use?.length === 1 &&
                suggestion?.campsite_type_of_use[0] === 'Day'
            ) {
                return <InventoryTag.InventoryDayUse isSpanItem isInverse />;
            }
            return <InventoryTag.InventoryCamping isSpanItem isInverse />;
        }
        if (
            suggestion.entity_type === 'ticketfacility' ||
            suggestion.entity_type === 'tour' ||
            suggestion.entity_type === 'timedentry' ||
            suggestion.entity_type === 'timedentry_tour'
        ) {
            return <InventoryTag.InventoryTicket className="tour-suggestion-icon" />;
        }
        if (
            suggestion.entity_type === 'permit' ||
            suggestion.entity_type === 'vehiclepermit'
        ) {
            return <InventoryTag.InventoryPermit isSpanItem isInverse />;
        }
        if (suggestion.entity_type === 'recarea') {
            return <InventoryTag.InventoryRecArea className="rec-area-suggestion-icon" />;
        }
        if (suggestion.entity_type === 'pass') {
            return <InventoryTag.InventorySitePass isSpanItem isInverse />;
        }
        if (suggestion.entity_type === 'activitypass') {
            return <InventoryTag.InventoryActivityPass isSpanItem isInverse />;
        }
        if (suggestion.entity_type === 'treepermit') {
            return <InventoryTag.InventoryTreePermits isSpanItem isInverse />;
        }
        if (
            suggestion.entity_type === 'venuereservations' ||
            suggestion.entity_type === 'venuereservations_venue'
        ) {
            return (
                <InventoryTag.InventoryVenueOther
                    tagIconName={<Icons.IconCelebration alt="Venue" />}
                    isSpanItem
                    isInverse
                />
            );
        }
        if (
            suggestion.entity_type === 'program' ||
            suggestion.entity_type === 'program_session'
        ) {
            return <InventoryTag.InventoryPrograms isSpanItem isInverse />;
        }
        if (suggestion.entity_type === 'giftcard') {
            return <InventoryTag.InventoryGiftCard isSpanItem isInverse />;
        }

        if (suggestion.entity_type === 'kb') {
            return (
                <div className="help-suggestion-icon">
                    <Icons.IconHelpOutline />
                </div>
            );
        }
        if (suggestion.entity_type === 'cms') {
            return (
                <div className="article-suggestion-icon">
                    <Icons.IconDescription isSpanItem />
                </div>
            );
        }
        if (suggestion.entity_type === 'page') {
            return (
                <div className="article-suggestion-icon">
                    <Icons.IconDescription isSpanItem />
                </div>
            );
        }
        if (suggestion.lat && !suggestion.entity_type) {
            return (
                <div className="location-suggestion-icon">
                    <Icons.IconPin />
                </div>
            );
        }

        if (suggestion.text === TEXT_SEARCH_NEAR_ME) {
            return <InventoryTag.InventoryMapPin isSpanItem isInverse />;
        }

        if (!suggestion.lat && suggestion.text) {
            return <Icons.IconHistory isSpanItem />;
        }

        return <InventoryTag.InventoryPOI className="poi-suggestion-icon" />;
    }

    // render suggestion and highlight match
    renderSuggestion(suggestion, { query }) {
        const suggestionText = this.getSuggestionValue(suggestion);

        if (suggestion.text === TEXT_CLEAR_SEARCH_HIST) {
            return (
                <div className="search-suggestion-container">
                    <div className={'search-suggestion-content search-suggestion-link'}>
                        <span>{suggestionText}</span>
                        <Icons.IconLoop />
                    </div>
                </div>
            );
        }

        let q = query;

        q = (q || '').trim();
        const matches = match(suggestionText, q);
        const parts = parse(suggestionText, matches);

        return (
            <div className="search-suggestion-container">
                <div className={'search-suggestion-icon'}>
                    {this.getSuggestionIcon(suggestion)}
                </div>
                <div className={'search-suggestion-content'}>
                    {parts.map((part, index) => {
                        const className = part.highlight ? 'highlight' : null;

                        return (
                            <span className={className} key={index}>
                                {part.text}
                            </span>
                        );
                    })}
                    <div className="search-suggestion-subtitle">
                        {suggestion.parent_name && <span>{suggestion.parent_name}</span>}
                        {suggestion.entity_type &&
                            suggestion.city &&
                            suggestion.state_code && (
                                <span>
                                    {`${suggestion.parent_name ? ' | ' : ''}Near ${
                                        suggestion.city
                                    }, ${suggestion.state_code}`}
                                </span>
                            )}
                    </div>
                </div>
            </div>
        );
    }

    renderSuggestionsContainer({ containerProps, children }) {
        let isAriaHidden = true;
        if (
            containerProps &&
            containerProps.className &&
            containerProps.className.includes('open')
        ) {
            isAriaHidden = false;
        }
        return (
            <div {...containerProps} role={'list'} aria-hidden={isAriaHidden}>
                {children}
            </div>
        );
    }

    renderInputComponent(props) {
        const inputID = 'search-filters-search-input';
        return (
            <div>
                <label htmlFor={inputID} className="rec-sr-only">
                    {`Search ${process.env.SITE_NAME}`}
                </label>
                <input
                    aria-label="Search location and press arrow keys to select suggestions"
                    {...props}
                />
                {this.state.searchText?.length > 0 && (
                    <span className="sarsa-text-field-button-wrapper">
                        <Button
                            appearance="subtle"
                            size="xs"
                            iconBeforeElement={<Icons.IconCloseCircle />}
                            screenReaderTextAfter="Clear search input"
                            className="sarsa-text-field-clear-button"
                            onClick={(e) => {
                                this.onSuggestionSelected(e, {
                                    suggestion: { text: TEXT_SEARCH_NEAR_ME },
                                });
                            }}
                        />
                    </span>
                )}
            </div>
        );
    }

    // render search input component
    render() {
        const inputProps = {
            id: 'search-filters-search-input',
            placeholder: this.props.placeholder,
            value: this.state.searchText ? this.state.searchText : '',
            onChange: this.onChange,
            onKeyPress: this.onKeyPress,
            onFocus: this.onFocus,
            onBlur: this.onBlur,
        };

        const suggestions = this.getSuggestions();

        return (
            <div className={`search-input-wrapper`}>
                <div className="form-item-wrap" role="search">
                    <Autosuggest
                        id={this.props.queryCriteriaPropertyName}
                        theme={theme}
                        multiSection
                        suggestions={suggestions}
                        alwaysRenderSuggestions={false}
                        highlightFirstSuggestion={false}
                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                        getSectionSuggestions={this.getSectionSuggestions}
                        getSuggestionValue={this.getSuggestionValue}
                        renderInputComponent={this.renderInputComponent}
                        renderSectionTitle={this.renderSectionTitle}
                        renderSuggestion={this.renderSuggestion}
                        renderSuggestionsContainer={this.renderSuggestionsContainer}
                        shouldRenderSuggestions={this.shouldRenderSuggestions}
                        onSuggestionSelected={this.onSuggestionSelected}
                        onSuggestionHighlighted={this.onSuggestionHighlighted}
                        focusFirstSuggestion={false}
                        inputProps={inputProps}
                    />
                    <div className="search-icon-holder" aria-hidden="true">
                        <Icons.IconSearch />
                    </div>
                </div>
            </div>
        );
    }
}

function select(state) {
    return {
        search: state.search,
        searchSuggestions: state.searchSuggestions,
    };
}

export default connect(select)(SearchInputContainer);
