/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Icons } from 'sarsaparilla';

export default function FacilityFilterBarModalTabButton({
    id,
    onClick,
    isActive,
    label,
    placeholder,
    value,
    onClearClick,
    clearButtonLabel,
}) {
    return (
        <div data-shared-ffb-modal-tab>
            <button
                id={id}
                onClick={onClick}
                className={cx('tab-button', {
                    active: isActive,
                })}
                aria-expanded={isActive}
            >
                <div>
                    <div className="label">{label}</div>
                    {!value && <div className="value placeholder">{placeholder}</div>}
                    {!!value && <div className="value">{value}</div>}
                </div>
            </button>
            <div className="clear-value-button-wrap">
                {!!value && !!onClearClick && (
                    <button
                        aria-label={clearButtonLabel}
                        className={cx('clear-value-button', {
                            active: isActive,
                        })}
                        onClick={onClearClick}
                    >
                        <Icons.IconCloseCircle size="md" />
                    </button>
                )}
            </div>
        </div>
    );
}

FacilityFilterBarModalTabButton.propTypes = {
    id: PropTypes.string,
    onClick: PropTypes.func,
    isActive: PropTypes.bool,
    label: PropTypes.node,
    placeholder: PropTypes.node,
    value: PropTypes.node,
    onClearClick: PropTypes.func,
    clearButtonLabel: PropTypes.node,
};
