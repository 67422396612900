/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, FlexCol, FlexRow, Icons, Alert } from 'sarsaparilla';
import { evAdapters, evLevels, evNetworks } from '../constants';

import mapViewSrc from './map-view.jpg';
import satelliteViewSrc from './satellite-view.jpg';

function LayersPanel({
    onTrailsClick,
    onEvClick,
    onMapStyleClick,
    hasTrails,
    hasEv,
    hasReservableLocations,
    showPanel,
    setShowPanel,
    isTrailVisible,
    isEvVisible,
    updateReservableLocationsLayer,
    updateEvLayer,
    mapEvFilters,
    mapFacilitiesToDisplay,
    isMobile,
    isSatelliteView,
}) {
    const hasPointsOfInterest = hasEv || hasTrails;

    const onCloseLayerPanel = () => {
        setShowPanel(false);
    };

    const getEvOptions = (evOptions) => {
        const resultOptions = Object.keys(evOptions).map((option) => {
            const checkedStatus = mapEvFilters?.current?.[option].shouldDisplay;
            return (
                <FlexCol xs={isMobile ? 12 : 4} key={`${option}-flex-col`}>
                    <Checkbox
                        id={option}
                        label={evOptions[option].label}
                        onChange={updateEvLayer}
                        isChecked={checkedStatus}
                        gaTrackingId="741085428153"
                        clickTagLabel={evOptions[option].label}
                        clickTagAction={checkedStatus ? 'Disabled' : 'Enabled'}
                    />
                </FlexCol>
            );
        });

        return resultOptions;
    };

    const evFilter = (
        <FlexRow className="ev-panel" xs={12}>
            <FlexRow xs={12} className="level-charger-container">
                <FlexCol xs={12}>
                    <div className="map-layers-title mt-2">LEVEL CHARGER</div>
                </FlexCol>
                {getEvOptions(evLevels)}
            </FlexRow>

            <FlexRow xs={12} className="preferred-charger-adapter">
                <FlexCol xs={12}>
                    <div className="map-layers-title mt-2">PREFERRED CHARGER ADAPTER</div>
                </FlexCol>
                {getEvOptions(evAdapters)}
            </FlexRow>

            <FlexRow xs={12} className="preferred-charger-network">
                <FlexCol xs={12}>
                    <div className="map-layers-title mt-2">
                        PREFERRED CHARGING NETWORK
                    </div>
                </FlexCol>
                {getEvOptions(evNetworks)}
            </FlexRow>
        </FlexRow>
    );

    const evDisclaimer = (
        <Alert type="info">
            EV charging station data is sourced from the Department of Energy. It is
            updated periodically and may not reflect the current status of EV charging
            availability.
        </Alert>
    );

    const pointsOfInterestSegment = (
        <FlexCol xs={12} className="map-poi-layers-container">
            {hasTrails && (
                <Checkbox
                    id="trails"
                    label="Trails"
                    isChecked={isTrailVisible}
                    onChange={onTrailsClick}
                    isDisabled={!hasTrails}
                    gaTrackingId="741085428153"
                    clickTagLabel="Trails"
                    clickTagAction={isTrailVisible ? 'Disabled' : 'Enabled'}
                />
            )}
            {hasEv && (
                <Checkbox
                    id="ev"
                    label="Show electric vehicle charging stations"
                    isChecked={isEvVisible}
                    onChange={onEvClick}
                    isDisabled={!hasEv}
                    gaTrackingId="741085428153"
                    clickTagLabel="EV"
                    clickTagAction={isEvVisible ? 'Disabled' : 'Enabled'}
                />
            )}
            {isEvVisible && evFilter}
            {isEvVisible && evDisclaimer}
        </FlexCol>
    );

    const reservableLocationsSegment = (
        <FlexRow className="map-reservable-locations" xs={12}>
            <FlexCol xs={12}>
                <div className="map-layers-title mt-2">RESERVABLE LOCATIONS</div>
            </FlexCol>
            {!!mapFacilitiesToDisplay?.current &&
                Object.keys(mapFacilitiesToDisplay?.current).map((element) => {
                    const facilityItem = mapFacilitiesToDisplay?.current?.[element];
                    return (
                        <FlexCol
                            xs={isMobile ? 12 : 4}
                            key={`${facilityItem?.id}-flex-col`}
                        >
                            <Checkbox
                                id={facilityItem?.id}
                                label={facilityItem?.label}
                                onChange={updateReservableLocationsLayer}
                                isChecked={facilityItem?.shouldDisplay}
                            />
                        </FlexCol>
                    );
                })}
        </FlexRow>
    );

    const panel = (
        <FlexRow className={isMobile ? 'mobile-map-layer-panel' : 'map-layer-panel'}>
            <FlexCol className="map-layers-container">
                <FlexCol className="map-layer-panel-close-button-container">
                    <Button
                        iconBeforeElement={<Icons.IconClose />}
                        appearance="subtle"
                        onClick={onCloseLayerPanel}
                        className="close-button"
                        size="sm"
                        aria-label="Close Map Layers panel"
                        gaTrackingId="331620035884"
                    />
                </FlexCol>
                <FlexRow className="map-style-layers-container">
                    <div className="map-style-layers">
                        <div className="map-layers-title">MAP VIEWS</div>
                        <FlexRow alignItems="center">
                            <FlexCol sm="variable">
                                <Button
                                    isUnstyled
                                    className={`map-layers-style-button ${!isSatelliteView ? 'selected-map-view' : ''}`}
                                    onClick={() => onMapStyleClick(false)}
                                    aria-label="Show default map"
                                    gaTrackingId="298685488762"
                                >
                                    <img
                                        src={mapViewSrc}
                                        alt=""
                                        data-ga-tracking-id="298685488762"
                                    />
                                    Default
                                </Button>
                            </FlexCol>
                            <FlexCol sm="variable">
                                <Button
                                    isUnstyled
                                    className={`map-layers-style-button ${isSatelliteView ? 'selected-map-view' : ''}`}
                                    onClick={() => onMapStyleClick(true)}
                                    aria-label="Show satellite map"
                                    gaTrackingId="828990255377"
                                >
                                    <img
                                        src={satelliteViewSrc}
                                        alt=""
                                        data-ga-tracking-id="828990255377"
                                    />
                                    Satellite
                                </Button>
                            </FlexCol>
                        </FlexRow>
                    </div>
                </FlexRow>
            </FlexCol>
            {hasReservableLocations && reservableLocationsSegment}
            {hasPointsOfInterest && pointsOfInterestSegment}
        </FlexRow>
    );

    return showPanel && panel;
}

export default LayersPanel;

LayersPanel.propTypes = {
    onTrailsClick: PropTypes.func,
    onEvClick: PropTypes.func,
    onMapStyleClick: PropTypes.func,
    hasTrails: PropTypes.bool,
    hasEv: PropTypes.bool,
    hasReservableLocations: PropTypes.bool,
    showPanel: PropTypes.bool,
    setShowPanel: PropTypes.func,
    isTrailVisible: PropTypes.bool,
    isEvVisible: PropTypes.bool,
    updateReservableLocationsLayer: PropTypes.func,
    updateEvLayer: PropTypes.func,
    mapEvFilters: PropTypes.object,
    mapFacilitiesToDisplay: PropTypes.object,
    displayTrailsMessage: PropTypes.bool,
    setDisplayTrailsMessage: PropTypes.func,
    isMobile: PropTypes.bool,
    isSatelliteView: PropTypes.bool,
};
