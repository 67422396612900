/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';

import { Inline } from 'sarsaparilla';

export function IconDetailBlockGroup({ children }) {
    return (
        <div data-component="IconDetailBlockGroup">
            <Inline space="sm">{children}</Inline>
        </div>
    );
}

IconDetailBlockGroup.propTypes = {
    /** One or more IconDetailBlock's */
    children: PropTypes.node,
};
