/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { Button, Icons } from 'sarsaparilla';
import PropTypes from 'prop-types';

export function createDownloadControl({ onClick } = {}) {
    return (
        <Button
            className="map-control-download-button"
            onClick={onClick}
            iconAfterElement={<Icons.IconFileDownload />}
            appearance="subtle"
            size="xs"
            aria-label="Download map as a PDF"
        >
            Download Map
        </Button>
    );
}

createDownloadControl.propTypes = {
    onClick: PropTypes.func,
};
