/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/* eslint-disable no-restricted-syntax */

import { Heading, TextBlock } from 'sarsaparilla';

interface RefundableSectionProps {
    notRefundableAnchor: string;
    refundableAnchor: string;
    nonRefundableAnchor: string;
}

export default function RefundableSection({
    notRefundableAnchor = '',
    refundableAnchor = '',
    nonRefundableAnchor = '',
}: RefundableSectionProps) {
    return (
        <section className="mb-8" id={notRefundableAnchor}>
            <Heading
                headingLevel={2}
                appearance="h2"
                id={notRefundableAnchor}
                className="mb-4"
            >
                What is and is not Refundable?
            </Heading>
            <Heading
                headingLevel={2}
                appearance="h3"
                className="mb-2"
                id={refundableAnchor}
            >
                Refundable
            </Heading>
            <TextBlock width="xl">
                <ul className="mb-6">
                    <li>
                        In the event of an <strong>emergency closure</strong>, the
                        Recreation.gov team or facility manager will refund all fees and
                        will attempt to notify you using the contact information within
                        your Recreation.gov{' '}
                        <a href="https://www.recreation.gov/account/profile">
                            customer profile
                        </a>
                        . Exception: Depending on agency policy, reservation fees for free
                        tickets, free campsites or free permits may not be refunded.
                    </li>
                </ul>
            </TextBlock>

            <Heading
                headingLevel={2}
                appearance="h3"
                className="mb-2"
                id={nonRefundableAnchor}
            >
                Non-Refundable
            </Heading>
            <TextBlock width="xl">
                <ul className="mb-6">
                    <li>
                        Service Fees: These include reservation fees, cancellation fees,
                        lottery application fees, and change fees
                    </li>
                    <li>Donations and any associated fees</li>
                    <li>Scan & Pay Camping payments</li>
                    <li>Site Passes and Activity Passes</li>
                    <li>Christmas Tree permits</li>
                    <li>Due Now fees</li>
                    <li>Down Payments</li>
                </ul>
            </TextBlock>
        </section>
    );
}
